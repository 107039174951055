import { render, staticRenderFns } from "./UserAvatar.vue?vue&type=template&id=fd7432e2&scoped=true"
import script from "./UserAvatar.vue?vue&type=script&lang=ts"
export * from "./UserAvatar.vue?vue&type=script&lang=ts"
import style0 from "./UserAvatar.vue?vue&type=style&index=0&id=fd7432e2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7432e2",
  null
  
)

export default component.exports