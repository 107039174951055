
import Vue from '@/vueTyped';
import { importMapboxGl } from '@/util.mapbox';
import { MBAddressObj, LngLat } from '@/types';

const CENTER_OF_THE_USA = [-98.5, 39.76];
const STREET_LEVEL_ZOOM = 17;
const DOUBLE_CLICK_ALLOWANCE = 300;

// We'll truncate coordinates so they aren't rounded in the database.
// https://app.asana.com/0/462513526071898/1197632670270675/f
function truncate(n: number): number {
    return parseFloat(n.toFixed(6));
}

export default Vue.extend({
    name: 'MapInput',

    props: {
        value: {
            type: Array, // as null | LngLat
            default: null,
        },

        defaultCenter: {
            type: Object, // as null | MBAddressObj
            default() {
                return { center: this.value ?? CENTER_OF_THE_USA };
            },
        },
    },

    data() {
        return {
            map: null as any, // null | Mapbox.Map,
            marker: null as any, // null | Mapbox.Marker,
            hasInteracted: false,
            lastDoubleClick: 0,
        };
    },

    watch: {
        value: 'handleValueChange',
        defaultCenter: 'handleDefaultCenterChange',
    },

    async mounted(this: any) {
        const Mapbox = await importMapboxGl();

        this.map = new Mapbox.Map({
            style: 'mapbox://styles/mapbox/streets-v11',
            container: this.$el,
            trackResize: true,
            center: this.value ?? this.defaultCenter?.center ?? CENTER_OF_THE_USA,
            zoom: STREET_LEVEL_ZOOM,
        });

        this.map.once('load', () => {
            this.map.resize();
        });

        this.map.addControl(new Mapbox.NavigationControl({ showCompass: false }), 'bottom-left');

        // Track double-clicks so we don't move the pin when we're just trying to zoom in.
        this.map.on('dblclick', this.handleMapDoubleClick);
        this.map.on('click', this.handleMapClick);

        this.marker = new Mapbox.Marker({ draggable: true });
        this.marker.on('dragend', this.handleMarkerDrop);

        if (this.value) {
            this.handleValueChange(this.value);
        } else {
            this.handleDefaultCenterChange(this.defaultCenter, null);
        }
    },

    destroyed(this: any) {
        this.marker?.off('dragend', this.handleMarkerDrop);
        this.marker?.remove();
        this.marker = null;

        this.map?.off('dblclick', this.handleMapDoubleClick);
        this.map?.off('click', this.handleMapClick);
        this.map?.remove();
        this.map = null;
    },

    methods: {
        focus() {
            this.$el.querySelector('canvas')?.focus();
        },
        handleMapDoubleClick(this: any) {
            this.lastDoubleClick = Date.now();
        },

        handleMapClick(this: any, event: any) {
            const clickTime = Date.now();

            setTimeout(() => {
                if (clickTime > this.lastDoubleClick) {
                    const { lng, lat } = event.lngLat;
                    this.$emit('input', [lng, lat].map(truncate));
                }
            }, DOUBLE_CLICK_ALLOWANCE);
        },

        handleMarkerDrop(this: any) {
            const { lng, lat } = this.marker.getLngLat();
            this.$emit('input', [lng, lat].map(truncate));
        },

        handleValueChange(this: any, value: null | LngLat) {
            if (this.map && this.marker) {
                if (value) {
                    const [lng, lat] = value;
                    this.marker.setLngLat({ lng, lat });
                    this.marker.addTo(this.map);

                    const inView = this.map.getBounds().contains({ lng, lat });
                    const atStreetLevel = this.map.getZoom() >= STREET_LEVEL_ZOOM;
                    if (!inView || !atStreetLevel) {
                        this.map.flyTo({
                            center: { lng, lat },
                            zoom: STREET_LEVEL_ZOOM,
                            speed: inView ? 2 : 100,
                        });
                    }
                } else {
                    this.marker.remove();
                }
            }
        },

        handleDefaultCenterChange(this: any, newCenter: null | MBAddressObj, oldCenter: null | MBAddressObj) {
            if (this.map && newCenter) {
                const map = this.map as any;
                const [lng, lat] = newCenter.center;
                const inView = map.getBounds().contains({ lng, lat });
                if (!oldCenter || !inView) {
                    if (newCenter.bbox) {
                        map.fitBounds(newCenter.bbox, {
                            speed : inView ? 2 : 100,
                        });
                    } else {
                        map.flyTo({
                            center: { lng, lat },
                            zoom: STREET_LEVEL_ZOOM,
                            speed : inView ? 2 : 100,
                        });
                    }
                }
            }
        },
    },
});
