
import Vue from '@/vueTyped';
import { MBAddressObj, LngLat } from '@/types';
import debounce from 'lodash/debounce';
import { geocode } from '@/util.mapbox';
import { getActiveLocation } from '@/util.location';

export default Vue.extend({
    name: 'LocationAutocomplete',
    props: {
        placeholder: {
            required: false,
            type: String,
            default: ' '
        },
        value: {
            required: false,
            type: Object,
            default: null,
        },
        geocodingParamTypes: {
            type: Array,
            required: false,
            default: (): null => null,
        },
        currentLocationText: {
            type: Boolean,
            default: false,
        },
        hideCurrentLocation: {
            type: Boolean,
            default: false,
        },
        showAddressMessage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            previousSearch: (this as any).getItemText(this.value),
            locationSuggestions: this.value ? [this.value] : [] as any[],
            loadingSuggestions: false,
            mobileSuggestionsShowing: false,
            loadingUserLocation: false,
        };
    },
    computed: {
        currentCoordinates(): LngLat | null {
            if (this.$store.state.activeLocation) {
                const [lng, lat] = this.$store.state.activeLocation.center;
                return [lng, lat];
            } else if (this.$store.state.account.currentUser) {
                const { lng, lat } = this.$store.state.account.currentUser;
                return [lng, lat];
            } else {
                return null;
            }
        },
        messages() {
            const messages = [];
            if (Array.isArray(this.$attrs.messages)) {
                messages.push(...this.$attrs.messages);
            } else if (this.$attrs.messages) {
                messages.push(this.$attrs.messages);
            }
            if (this.showAddressMessage) {
                messages.unshift('__ADDRESS__');
            }
            return messages;
        },
        internalValue: {
            get(): null | MBAddressObj {
                return this.value;
            },
            set(val: null | MBAddressObj) {
                this.$emit('input', val);
            },
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    if (!this.locationSuggestions.includes(value)) {
                        this.locationSuggestions = [value];
                    }
                } else {
                    this.locationSuggestions = [];
                }
            },
        },
    },
    methods: {
        getItemText(item: any) {
            return item?.place_name ?? item?.text ?? '';
        },
        fetchLocations: debounce(async function fetchLocations(this: any, search: string) {
            this.geocodingError = false;
            this.loadingSuggestions = true;
            try {
                const res = await geocode(search, {
                    proximity: this.currentCoordinates,
                    limit: this.$vuetify.breakpoint.xsOnly ? 3 : 4,
                    types: this.geocodingParamTypes
                });
                this.locationSuggestions = res.features;
                this.mobileSuggestionsShowing = true;
                if (this.$vuetify.breakpoint.xsOnly) {
                    await this.$nextTick();
                    this.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            } catch (err) {
                this.geocodingError = true;
            } finally {
                this.loadingSuggestions = false;
            }
        }, 500),
        async findCurrentLocation() {
            this.loadingUserLocation = true;
            const result = await getActiveLocation();
            if (result.location) {
                this.internalValue = result.location;
            } else {
                if (result.error) {
                    this.$store.dispatch('alertUser', {
                        type: 'error',
                        message: result.error,
                    });
                }
            }
            this.loadingUserLocation = false;
        },
        handleSearchChange(search: null | string) {
            const searchChanged = search !== this.previousSearch;
            const searchIsntCurrentValue = search !== this.getItemText(this.value);
            if (search && typeof search === 'string' && searchChanged && searchIsntCurrentValue) {
                this.previousSearch = search;
                this.fetchLocations(search);
            }
        },
        handleSuggestionSelection(location: MBAddressObj) {
            if (this.value !== location) {
                this.internalValue = location;
            }

            this.mobileSuggestionsShowing = false;
        }
    },
});
