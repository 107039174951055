import { BASE_API_URL, CLIENT_ORIGIN } from '@/config';
import router from '@/router';
import RouteNames from '@/router/names';
import { CapacitorHttp } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const DEFAULT_AUTHORIZATION_PARAMS = {
    response_type: 'code',
    scope: '',
    state: '',
};

export async function getOauthCode(searchParams?: URLSearchParams) {
    const { value: authToken } = await Preferences.get({ key: 'auth-token' });
    if (!authToken) throw new Error('NO_AUTH');

    const authorizationUrl = new URL(`${BASE_API_URL}/../v3/oauth2/authorize`);

    searchParams?.forEach((value, key) => {
        authorizationUrl.searchParams.append(key, value);
    });

    Object.entries(DEFAULT_AUTHORIZATION_PARAMS).forEach(([key, value]) => {
        if (!authorizationUrl.searchParams.has(key)) {
            authorizationUrl.searchParams.set(key, value);
        }
    });

    // We're going to sort of proxy our own request and then
    // assign the location manually instead of letting it redirect.
    authorizationUrl.searchParams.set('redirect_uri', CLIENT_ORIGIN);

    if (!authorizationUrl.searchParams.has('client_id')) {
        throw new Error('A client_id param is required');
    }

    // Axios doesn't seem to expose the redirected URL, but CapacitorHttp does.
    const response = await CapacitorHttp.get({
        url: authorizationUrl.href,
        headers: { authorization: `Bearer ${authToken}` },
    });

    if (response.status >= 400 && response.status < 500) {
        // The token's no good! Maybe it expired.
        throw new Error('NO_AUTH');
    } else if (response.status >= 500 && response.status < 600) {
        throw new Error(`${response.status} ${JSON.stringify(response.data)}`);
    }

    const codeFromResponse = new URL(response.url).searchParams.get('code');
    if (!codeFromResponse) throw new Error(`No code in ${response.url}`);

    return codeFromResponse;
}

export async function doOauth() {
    try {
        const currentUrl = new URL(location.href);
        const codeFromResponse = await getOauthCode(currentUrl.searchParams);

        const givenRedirectUri = currentUrl.searchParams.get('redirect_uri');
        if (!givenRedirectUri) throw new Error('No redirect_uri param given');

        const actualRedirectUrl = new URL(givenRedirectUri);
        actualRedirectUrl.searchParams.set('code', codeFromResponse);

        location.replace(actualRedirectUrl);
    } catch (error) {
        if (error instanceof Error && error.message === 'NO_AUTH') {
            const loginUrl = router.resolve({
                name: RouteNames.LOGIN,
                query: { redirect: location.href.slice(location.origin.length) },
            });

            location.replace(loginUrl.href);
        } else {
            throw error;
        }
    }
}
