
import Vue from '@/vueTyped';
import MarkdownOutput from './MarkdownOutput.vue';

export default Vue.extend({
    components: {
        MarkdownOutput,
    },

    props: {
        inputId: {
            type: String,
            default: null
        }
    },
});
