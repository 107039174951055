
declare const require: Function;
import Vue from '@/vueTyped';
export default Vue.extend({
    name: 'UserAvatar',
    props: {
        imageOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'default',
        },
        link: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dimension() {
            switch (this.size) {
                case 'small':
                    return 48;
                case 'medium':
                    return 64;
                case 'large':
                    return 150;
                case 'extra-large':
                    return 260;
                default:
                    return 48;
            }
        },
        fullname(): string {
            return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
        },
        avatarImgProps(): any {
            return {
                class: 'avatar__img',
                title: this.fullname,
                // @ts-ignore
                style: `background-image: url(${this.user.avatar ? this.$options.filters.formatImage(this.user.avatar, this.dimension, this.dimension) : require('@/assets/images/default_profile_image.png')})`
            };
        }
    }
});
